import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from "@@/core/mixins/mixins";
import {BTooltip, BTable} from "bootstrap-vue";
import configProvider from "@@/core/configProvider";
import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload";

let fields = [
  {
    key: "id",
    label: "شناسه",
  },
]


if (configProvider.get('newsletter.phoneNumber')) {
  fields.push({
    key: "phone_number",
    label: "شماره تماس",
  },)
} else {
  fields.push({
    key: "email",
    label: "ایمیل",
  },)
}

fields = [...fields, ...[  {
  key: "created_at",
  label: "تاریخ ثبت",
},
  {
    key: 'delete',
    label: 'حذف'
  },]]

export default {
  name: "Member",
  components: {
    Loading, BTooltip, BTable, ExcelDownload
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/newsletters/users",
      fields: fields
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {},
  methods: {},
};
