<template>
    <main class="mt-5">
        <span id="add-btn">
            <button  :disabled="hasPermission('write_unit') ? false : true" @click="createItem" v-b-modal.color class="btn btn-success">واحد جدید</button>
        </span>
        <b-tooltip v-if="!hasPermission('write_unit')" target="add-btn" triggers="hover">
          شما به این بخش دسترسی ندارید
        </b-tooltip>
        <FormModal :edit-model="edit"
                   :mode="mode"
                   @create="onCreate"
                   @edit="onEdit"
                   :url="url" :modal-name="$options.name"
                   :create-rules="createRules" :edit-rules="editRules"></FormModal>
        <div class="card mt-3">
            <div class="card-header  border-0">
                <h5 class="card-title font-bold">واحد ها</h5>
            </div>
            <div class="card-body">
                <div>
                    <b-table responsive hover bordered :items="items.data" :fields="fields">
                        <template v-slot:cell(created_at)="data">
                            {{ data.item.created_at | persianDate}}
                        </template>
                        <template v-slot:cell(status)="data">
                            <span v-if="data.item.status == 1 " class="badge badge-success status" >فعال</span>
                            <span v-else-if="data.item.status == 0" class="badge badge-danger status">غیرفعال</span>
                        </template>
                        <template v-slot:cell(edit)="data">
                            <span :id="'edit-btn-' + data.item.id">
                                <a :disabled="!hasPermission('modify_unit') ? true : false "  class="action-btns1  pt-1 px-2" @click="editItem(data.index)">
                                    <i class="feather feather-edit  text-primary"></i>
                                </a>
                              </span>
                              <b-tooltip v-if="!hasPermission('modify_unit')" :target="'edit-btn-' + data.item.id" triggers="hover">
                                شما به این بخش دسترسی ندارید
                              </b-tooltip>
                        </template>
                        <template v-slot:cell(delete)="data">
                            <span :id="'delete-btn-' + data.item.id">
                                <a :disabled="!hasPermission('delete_unit') ? true : false " class="action-btns1  pt-1 px-2 " @click="deleteItem(data.item.id)">
                                    <i class="feather feather-trash-2 text-danger"></i>
                                </a>
                              </span>
                              <b-tooltip v-if="!hasPermission('delete_unit')" :target="'delete-btn-' + data.item.id" triggers="hover">
                                شما به این بخش دسترسی ندارید
                              </b-tooltip>
                        </template>
                    </b-table>
                    <Loading v-if="$root.isEmptyObject(items)" />
                    <pagination v-if="items.data != ''" :limit="2" :disabled="disabled" :data="items" @pagination-change-page="changePage">
                    </pagination>
                    <div v-if="items.data == ''" class="alert alert-danger">
                        موردی یافت نشد
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import UnitImpl from '@@/core/pages/Unit/UnitImpl'
export default {
    extends: UnitImpl
}
</script>