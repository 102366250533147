<template>
  <b-modal
      :ok-disabled="disabled"
      :cancel-disabled="disabled"
      @ok.prevent="submit"
      class="in-form-modal"
      ok-title="ثبت"
      cancel-title="لغو"
      id="modal-SizeChartType"
      :title="mode == 'edit' ? 'ویرایش نوع' : 'نوع جدید'"
  >
    <form @submit.prevent="submit">
      <b-form-group>
        <label> نام <i class="text-danger">*</i></label>
        <b-form-input name="name" v-model="name" placeholder="نام" />
      </b-form-group>
      <div
          class="d-flex plus-negative-container mt-2"
          v-for="(item, index) in values"
          :key="index"
      >
        <button
            type="button"
            class="btn btn-success"
            @click="addValue(index)"
        >
          +
        </button>
        <button
            type="button"
            :disabled="disabled || values.length === 1"
            class="btn btn-danger neg"
            @click="
                  values = $root.deleteArrayRow(
                    index,
                    values
                  )
                "
        >
          ‒
        </button>
        <template>
          <b-form-input
              placeholder="نوع"
              v-model="values[index]"
          ></b-form-input>
        </template>
      </div>
    </form>

  </b-modal>
</template>
<script>
import SizeChartTypeModalImpl from "@@/core/components/size/SizeChartTypeModal/SizeChartTypeModalImpl";
export default {
  extends: SizeChartTypeModalImpl
}
</script>
<style scoped>
.plus-negative-container {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.plus-negative-container > * {
  min-width: 30px;
  font-size: 1rem;
  padding: 3px 7px;
  cursor: pointer;
  line-height: normal;
}
</style>
