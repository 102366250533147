<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">
          {{ $route.params.id ? "ویرایش کمپین" : "کمپین جدید" }}
        </h5>
        <div style="flex:1"></div>
        <Back />
      </div>
      <div class="card-body">
        <flashForm
          ref="flashForm"
          :mode="$route.params.id ? 'edit' : 'create'"
          :url="url"
          :flash="flash"
        />
      </div>
    </div>
  </main>
</template>
<script>
import FlashCreateEditImpl from '@@/core/pages/flash/FlashCreateEdit/FlashCreateEditImpl'
export default {
  extends: FlashCreateEditImpl
}
</script>
