<template>
  <b-modal
      id="store-modal"
      :title="mode === 'increment' ? 'اضافه کردن به انبار' : 'کم کردن از انبار'"
      :ok-disabled="disabled || disabledBtn"
      :cancel-disabled="disabled || disabledBtn"
      @ok.prevent="submit"
      class="in-form-modal"
      ok-title="ثبت"
      cancel-title="لغو"
      size="lg"
  >
    <form ref="form" @submit.prevent="submit">
      <ProductSearch :selectable="true"
                     class="row" :disabled="disabled" :show-label="true"
                     @selectVariety="selectVariety"/>
      <b-form-group
          v-if="store.selectedVariety != null && store.selectedVariety != ''"
      >
        <div class="d-flex justify-content-between">
          <label for="quantity"> تعداد <i class="text-danger">*</i></label>
          <span> موجودی در انبار : {{ store.selectedVariety.quantity }} </span>
        </div>
        <b-form-group>
          <b-form-input class="w-50"
              id="quantity"
              name="quantity" type="number"
              v-model="store.quantity"
          ></b-form-input>
        </b-form-group>
      </b-form-group>
      <span v-else-if="store.selectedVariety == ''" class="text-danger">
        این محصول تنوعی ندارد .
      </span>
      <b-form-group
          v-if="store.selectedVariety != null && store.selectedVariety != ''"
      >
        <label for="des"> توضیح</label>
        <b-form-textarea
            id="desc"
            name="description"
            v-model="store.description"
        ></b-form-textarea>
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>
import StoreFormImpl from '@@/core/components/store/StoreForm/StoreFormImpl'
import ProductSearch from "@@/core/components/shared/ProductSearch/ProductSearch";

export default {
  components: {ProductSearch},
  extends: StoreFormImpl
}
</script>
