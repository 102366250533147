import mixins from "@@/core/mixins/mixins";
import {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, BFormCheckbox, VBToggle} from "bootstrap-vue";

export default {
  name: 'Wallet',
  mixins: [mixins],
  components: {
    BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, BFormCheckbox
  },
  mounted() {
    this.loadItems()
  },
  directives: {
    'b-toggle': VBToggle
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        deposit: this.filter.deposit,
        start_date: this.filter.start_date ? window.moment(this.filter.start_date, 'YYYY.MM.DD').unix() : '',
        end_date: this.filter.end_date ? window.moment(this.filter.end_date, 'YYYY.MM.DD').unix() : '',
      }
    },
  },
  data() {
    return {
      filter: {
        deposit: false,
        start_date: '',
        end_date: '',
      },
      url: 'admin/customers/transactions',
      transactionsFields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'amount',
          label: 'مبلغ (تومان)'
        },
        {
          key: 'type',
          label: 'نوع'
        },
        {
          key: 'confirmed',
          label: 'وضعیت'
        },
        {
          key: 'description',
          label: 'توضیحات'
        },
        {
          key: 'tracking_code',
          label: 'شناسه پرداخت'
        },
        {
          key: 'created_at',
          label: 'تاریخ'
        }
      ],
    }
  }
}
