<template>
  <main class="mt-5">
    <span id="add-btn">
      <button :disabled="hasPermission('write_size_chart') ? false : true" @click="createItem"
                   class="btn btn-success">ایجاد نوع چارت جدید</button>
    </span>
    <b-tooltip v-if="!hasPermission('write_size_chart')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">  نوع سایز چارت ها</h5>
        <div style="flex:1"></div>
        <Back url="/admin/size-chart" />
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items" :fields="fields">
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate}}
            </template>
            <template v-slot:cell(show)="data">
              <span>
                <button @click="showItem(data.item)" class="action-btns1">
                  <i class="feather feather-eye  text-primary"></i>
                </button>
              </span>
            </template>
            <template v-slot:cell(edit)="data">
              <span>
                <button @click="editItem(data.index)" class="action-btns1">
                  <i class="feather feather-edit  text-primary"></i>
                </button>
              </span>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_size_chart') ? true : false " class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('delete_size_chart')" :target="'delete-btn-' + data.item.id"
                triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <div v-if="items == ''" class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
    <SizeChartTypeModal @create="onCreate" @edit="onEdit" :item="edit" :mode="mode" />
    <SizeChartTypeShowModal :size-chart-type="edit" />
  </main>
</template>
<script>
import SizeChartTypeImpl from '@@/core/pages/sizeChart/SizeChartType/SizeChartTypeImpl'
import SizeChartTypeShowModal from "@@/core/components/size/SizeChartTypeShowModal/SizeChartTypeShowModal";
export default {
  components: {SizeChartTypeShowModal},
  extends: SizeChartTypeImpl
}
</script>
