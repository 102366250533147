import Loading from '@@/core/components/shared/Loading/Loading'
import sizeChartModal from '@@/core/components/size/SizeChartModal/SizeChartModal'
import mixins from '@@/core/mixins/mixins'
import {BTooltip, BTable} from "bootstrap-vue";

export default {
  name: "Size",
  components: {
    Loading,
    sizeChartModal,
    BTooltip, BTable
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/size_chart',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'title',
          label: 'عنوان'
        },
        {
          key: 'chart',
          label: 'چارت'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      sizeChartData: null
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    showSizeChart(item) {
      this.sizeChartData = item.chart
      if (typeof this.sizeChartData === 'string') {
        this.sizeChartData = JSON.parse(this.sizeChartData)
      }
      this.$root.$emit('bv::show::modal', 'size-chart-modal')
    }
  }
}
