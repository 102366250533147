<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">{{ $route.params.id ? 'ویرایش مطلب' : 'مطلب جدید' }}</h5>
        <div style="flex:1"></div>
        <Back ref="back" />
      </div>
      <div class="card-body">
        <Form ref="form"
              url="admin/posts"
              :mode="mode" :edit-model="editModel"
              :create-rules="createRules" :edit-rules="editRules" :selects="widgetData" />
      </div>
    </div>
  </main>
</template>
<script>
import PostCreateEditImpl from '@@/core/pages/post/PostCreateEdit/PostCreateEditImpl'
export default {
  extends: PostCreateEditImpl
}
</script>