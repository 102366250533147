<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> htaccess</h5>
      </div>
      <div class="card-body">
        <form @submit.prevent="submit">
          <b-form-textarea style="direction: ltr" dir="ltr" cols="30" rows="10" v-model="htaccess" />
          <button class="btn btn-success mt-3 px-5" type="submit">ثبت</button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import {BFormTextarea} from "bootstrap-vue";

export default {
  name: "Htaccess.vue",
  components: {BFormTextarea},
  data() {
    return {
      htaccess: ''
    }
  },
  mounted() {
    this.loadHtaccess()
  },
  methods: {
    async loadHtaccess() {
      const response = await this.$axios.get('admin/htaccess')
      this.htaccess = response.data.data.data;
    },
    async submit() {
      try {
        const response = await this.$axios.post('admin/htaccess', {
          data: this.htaccess
        })
        this.$root.notify(response.data.message, 'success')
      } catch (e) {
        this.$root.notify(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
