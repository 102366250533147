<template>

  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="19"
       viewBox="0 0 518.462 518.462" style="enable-background:new 0 0 518.462 518.462;" xml:space="preserve">
<g>
	<g>
		<path fill="#fff" stroke-width="20" d="M518.462,22.82H0l193.159,203.495l-0.014,269.327l132.173-68.37l-0.014-200.957L518.462,22.82z M212.837,463.286
			l0.014-244.827L45.846,42.512h426.769L305.611,218.459l0.014,196.832L212.837,463.286z"/>
	</g>
</g>
</svg>

</template>

<script>
export default {
  name: "FilterSvg"
}
</script>

<style scoped>

</style>
