import Back from '@@/core/components/shared/Back/Back'
import Ckeditor from '@@/core/components/shared/Ckeditor/Ckeditor'
import {BFormGroup, BFormInput} from "bootstrap-vue";


export default {
  components: {
    Back,
    Ckeditor,
    BFormGroup, BFormInput
  },
  data() {
    return {
      title: "",
      url: "admin/newsletters",
      newsletters: {
        title: "",
        body: "",
        send_at: "",
        send_all: 1,
      },
      disabled: false,
    };
  },
  mounted() {},
  methods: {
    async send() {
      this.disabled = true;
      try {
        const response = await this.$axios.post("admin/newsletters", {
          title: this.newsletters.title,
          body: this.newsletters.body,
          send_all: this.newsletters.send_all,
          send_at: this.newsletters.send_at
            ? window
                .moment(this.newsletters.send_at, "YYYY.MM.DD HH:mm:ss")
                .format("YYYY-MM-DD HH:mm:ss")
            : window
                .moment(new Date(), "YYYY.MM.DD HH:mm:ss")
                .add(20, 'seconds')
                .format("YYYY-MM-DD HH:mm:ss"),
        });

        this.$root.notify(response.data.message, "success");
        this.$router.push("/admin/newsletters");
      } catch (error) {
        this.$root.notify(error);
      }

      this.disabled = false;
    },
  },
};
