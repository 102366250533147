<template>
  <b-modal
    id="attribute-modal"
    :title="mode === 'edit' ? 'ویرایش ویژگی' : 'ثبت ویژگی'"
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    @ok="submit"
    class="in-form-modal"
    ok-title="ثبت"
    cancel-title="لغو"
  >
    <b-form ref="form" @submit.prevent="submit">
      <div class="row">
        <b-form-group class="col-md-12">
          <label for="name"> نام <i class="text-danger">*</i></label>
          <b-form-input
            :disabled="disabled"
            ref="name"
            id="title"
            name="name"
            :value="attribute.name"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-12" label="لیبل" label-for="label">
          <b-form-input
            :disabled="disabled"
            ref="label"
            id="label"
            name="label"
            :value="attribute.label"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group class="col-md-12">
          <label for="style"> نحوه نمایش <i class="text-danger">*</i></label>
          <multiselect
              v-model="attribute.style"
              placeholder="انتخاب کنید"
              :allow-empty="false"
              open-direction="bottom"
              :options="styleOptions"
              label="name"
              track-by="value"
              :searchable="false"
              :show-labels="false"
              :disabled="disabled ? true : false"
          >
            <template slot="noOptions">
              موردی یافت نشد
            </template>
            <template slot="noResult">
              موردی یافت نشد
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group class="col-md-12">
          <label for="type"> نوع <i class="text-danger">*</i></label>
          <multiselect
            v-model="attribute.type"
            placeholder="انتخاب کنید"
            :allow-empty="false"
            open-direction="bottom"
            :options="typeOptions"
            label="name"
            track-by="value"
            :searchable="false"
            :show-labels="false"
            :disabled="disabled || mode == 'edit' ? true : false"
          >
            <template slot="noOptions">
              موردی یافت نشد
            </template>
            <template slot="noResult">
              موردی یافت نشد
            </template>
          </multiselect>
        </b-form-group>
        <div class="col-md-12 row">
          <div class="col-md-6 ">
            <b-form-checkbox
              id="status"
              v-model="attribute.status"
              name="status"
              value="1"
              unchecked-value="0"
            >
              <span class="pr-5">وضعیت</span>
            </b-form-checkbox>
          </div>
          <div class="col-md-6">
            <b-form-checkbox
              id="show_filter"
              v-model="attribute.show_filter"
              name="show_filter"
              value="1"
              unchecked-value="0"
            >
              <span class="pr-5"> نمایش در فیلتر</span>
            </b-form-checkbox>
          </div>
        </div>
        <transition name="fade">
          <b-form-group
            v-if="
              attribute.type != null &&
                attribute.type.value == 'select' &&
                attribute.values != ''
            "
            class="col-md-12 mt-5"
          >
            <label for="type"> مقادیر <i class="text-danger">*</i></label>
            <div
              class="d-flex plus-negative-container mt-2"
              v-for="(item, index) in attribute.values"
              :key="index"
            >
              <button
                type="button"
                class="btn btn-success"
                @click="addValue(index)"
              >
                +
              </button>
              <button
                type="button"
                :disabled="btnDisabled || item.id ? true : false"
                class="btn btn-danger neg"
                @click="
                  attribute.values = $root.deleteArrayRow(
                    index,
                    attribute.values
                  )
                "
              >
                ‒
              </button>
              <template v-if="item.id">
                <input
                  type="hidden"
                  :value="item.id"
                  :name="'edited_values[' + index + '][id]'"
                />
                <b-form-input
                  placeholder="مقدار"
                  v-model="item.value"
                  :name="'edited_values[' + index + '][value]'"
                ></b-form-input>
              </template>
              <template v-else>
                <b-form-input
                  placeholder="مقدار"
                  v-model="item.value"
                  :name="'values[' + index + ']'"
                ></b-form-input>
              </template>
            </div>
          </b-form-group>
        </transition>
        <!-- <div class="col-md-12 text-center mt-5">
            <button :disabled="disabled" type="submit" class="btn btn-success">
                {{ mode == 'edit' ? ' ویرایش ویژگی' : ' ثبت ویژگی' }}</button>
        </div> -->
        <input type="submit" style="visibility: hidden;position: absolute" />
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import AttributeFormImpl from '@@/core/components/attribute/AttributeForm/AttributeFormImpl'
export default {
  extends: AttributeFormImpl
};
</script>

<style scoped src="@@/core/components/attribute/AttributeForm/AttributeFormStyle.css"></style>
