import {BModal, BFormGroup, BFormInput} from "bootstrap-vue";

export default {
    components: {
        BModal, BFormGroup, BFormInput
    },
    props: {
        role: Object,
        mode: {
            default: 'create',
            type: String
        },
        url: String,
    },
    data() {
        return {
            disabled: false,
            startDate: '',
            endDate: '',
            permissions: []
        }
    },
    mounted() {
        this.loadPermissions()
    },
    methods: {
        loadPermissions(){
            this.$axios.get('admin/permissions')
            .then(response => {
                this.permissions = response.data.data.permissions['admin-api']
            })
        },
        async submit() {
            this.disabled = true
            try {
                let formData = new FormData(this.$refs.form)
                formData.append('_method' , this.mode == 'edit' ? 'PUT' : 'post')
                const response = await this.$axios.post(this.url , formData);
                this.$root.notify(response.data.message, 'success')
                if (this.mode == 'edit') {
                    this.$root.$set(this.$parent._data.items.data, this.role.index, response.data.data.role);
                } else {
                    this.$parent._data.items.data.unshift(response.data.data.role)
                }
                this.$root.$emit('bv::hide::modal', 'role-modal')
            } catch (error) {
                this.$root.notify(error)
            }
            this.disabled = false
        },
    }
}
