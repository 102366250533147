import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from "@@/core/mixins/mixins";
import {BTooltip, BTable} from "bootstrap-vue";

export default {
  name: "Set",
  components: {
    Loading,
    BTooltip, BTable
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/product_sets",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
        {
            key: 'delete',
            label: 'حذف'
          },
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {},
  methods: {},
};
