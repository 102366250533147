import Back from '@@/core/components/shared/Back/Back';
import Ckeditor from '@@/core/components/shared/Ckeditor/Ckeditor';

export default {
  components: {
    Back,
    Ckeditor,
  },
  data() {
    return {
      url: "admin/newsletters",
      newsletters: {},
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getNewsletters(this.$route.params.id);
    }
  },
  methods: {
    async getNewsletters(id) {
      const response = await this.$axios.get(this.url + "/" + id);
      this.newsletters = response.data.data.newsletters;
    },
  },
};
