<template>
  <div>
    <div @click="onClick(key)" :style="{background: getStatusColor(key)}" class="status-box"
         :class="{selected: isSelectable && key == (typeof value === 'string' ? value : (value.value)),
    'status-box-selectable': isSelectable}"
         v-for="[key,_value] of Object.entries(finalOrderStatuses)">
      <span>{{getStatusLabel(key)}}</span><span>{{_value}}</span>
    </div>
  </div>
</template>

<script>
import {getStatusLabel, getStatusColor} from "@@/core/libraries/adminFunctions";

export default {
  name: "OrderStatusesView",
  props: {
    orderStatuses: Object,
    noEmpty: {
      default: false,
      type: Boolean
    },
    value: {
      default: false,
      type: [String, Object, Boolean]
    }
  },
  computed: {
    isSelectable() {
      return Boolean(this.value)
    },
    finalOrderStatuses() {
      if (this.noEmpty) {
        let final = {}
        for (let [index, value] of Object.entries(this.orderStatuses)) {
          if (value > 0) {
            final[index] = value;
          }
        }
        return final
      }
      return this.orderStatuses
    },
  },
  methods:{
    getStatusLabel, getStatusColor,
    onClick(key) {
      this.$emit('input', {
        value: key,
        title: getStatusLabel(key)
      })
    }
  }
}
</script>
<style scoped>
.status-box {
  display: inline-block;
  margin-left: 5px;
  border: 1px solid;
  border-radius: 10px;
  padding: 7px 9px;
  cursor: pointer;
  color: white;
  transition: all 300ms;
}

.status-box:hover {
  box-shadow: 0 0 10px -5px #8a8a8a;
}

.status-box span:nth-child(2) {
  margin-right: 5px;
}

.status-box-selectable:not(.selected) {
  background: white!important;
  color: black;
}

.selected {
  box-shadow: 0 0 10px -2px #8a8a8a!important;
  outline: 1px solid white;
}

</style>
