<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link :disabled="hasPermission('write_flash') ? false : true" to="/admin/flash/create"
        class="btn btn-success">کمپین جدید</router-link>
    </span>
    <b-tooltip v-if="!hasPermission('write_flash')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> کمپین فروش</h5>
        <div style="flex:1"></div>
      </div>

      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(status)="data">
              <span v-if="data.item.status == 1 " class="badge badge-success status">فعال</span>
              <span v-else-if="data.item.status == 0" class="badge badge-danger status">غیرفعال</span>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate}}

            </template>
            <template v-slot:cell(start_date)="data">
              {{moment(data.item.start_date).format('HH:MM YYYY/MM/DD ')}}
            </template>
            <template v-slot:cell(end_date)="data">
              {{moment(data.item.end_date).format('HH:MM YYYY/MM/DD ')}}
            </template>
            <template v-slot:cell(image)="data">
              <ImageHolder style="max-height: 70px" :image="data.item.image"  v-if="data.item.image">
                <img :src="data.item.image.url">
              </ImageHolder>
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <router-link :disabled="!hasPermission('modify_flash') ? true : false "
                  :to="{name:'flash.edit' , params:{ id: data.item.id}}" class="action-btns1  pt-1 px-2">
                  <i class="feather feather-edit  text-primary"></i>
                </router-link>
              </span>
              <b-tooltip v-if="!hasPermission('modify_flash')" :target="'edit-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_flash') ? true : false " class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('delete_flash')" :target="'delete-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
            @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>

  </main>
</template>
<script>
import FlashImpl from '@@/core/pages/flash/Flash/FlashImpl'
export default {
  extends: FlashImpl
}
</script>
