<template>
    <main class="mt-5">
        <span id="add-btn">
            <button  :disabled="hasPermission('modify_faq') || !disabled ? false : true" @click="updateInstagram" class="btn btn-warning">به روز رسانی </button>
        </span>
        <b-tooltip v-if="!hasPermission('modify_faq')" target="add-btn" triggers="hover">
            شما به این بخش دسترسی ندارید
        </b-tooltip>

        <div class="card mt-3">
            <div class="card-header  border-0">
                <h5 class="card-title font-bold"> اینستاگرام</h5>
            </div>
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-md-3 col-sm-6 col-12  mt-2 px-1" v-for="instagram in items.data"
                            :key="instagram.id">
                            <a :href="instagram.link" class="instagram-post text-center w-100 h-100 d-block position-relative"
                                target="_blank">
                                <img v-if="instagram.image" class="" :src="instagram.image.url" alt="">
                                <span class="py-1 px-2 border-radius7 instagram-icon text-center">
                                    <span class="ml-2">
                                        <span class="text-white">{{instagram.comments}}</span>
                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M7.69524 13.3909C7.05048 13.3909 6.52667 12.8671 6.52667 12.2223C6.52667 11.5794 7.05048 11.0556 7.69524 11.0556C8.34001 11.0556 8.86382 11.5794 8.86382 12.2223C8.86382 12.8671 8.34001 13.3909 7.69524 13.3909ZM12.2381 13.3909C11.5933 13.3909 11.0695 12.8671 11.0695 12.2223C11.0695 11.5794 11.5933 11.0556 12.2381 11.0556C12.8829 11.0556 13.4067 11.5794 13.4067 12.2223C13.4067 12.8671 12.8829 13.3909 12.2381 13.3909ZM15.6125 12.2223C15.6125 12.8671 16.1363 13.3909 16.7811 13.3909C17.4258 13.3909 17.9496 12.8671 17.9496 12.2223C17.9496 11.5794 17.4258 11.0556 16.7811 11.0556C16.1363 11.0556 15.6125 11.5794 15.6125 12.2223Z"
                                                fill="#fff" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M12.02 2C6.21 2 2 6.74612 2 12.015C2 13.6975 2.49 15.4291 3.35 17.0115C3.51 17.2729 3.53 17.6024 3.42 17.9139L2.75 20.1572C2.6 20.698 3.06 21.0976 3.57 20.9374L5.59 20.3375C6.14 20.1572 6.57 20.3866 7.08 20.698C8.54 21.5583 10.36 22 12 22C16.96 22 22 18.1642 22 11.985C22 6.65598 17.7 2 12.02 2Z"
                                                stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span>
                                        <span class="text-white">{{instagram.likes}}</span>
                                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M11.761 20.8538C9.5904 19.5179 7.57111 17.9456 5.73929 16.1652C4.45144 14.8829 3.47101 13.3198 2.8731 11.5954C1.79714 8.25031 3.05393 4.42083 6.57112 3.28752C8.41961 2.69243 10.4384 3.03255 11.9961 4.20148C13.5543 3.03398 15.5725 2.69398 17.4211 3.28752C20.9383 4.42083 22.2041 8.25031 21.1281 11.5954C20.5302 13.3198 19.5498 14.8829 18.2619 16.1652C16.4301 17.9456 14.4108 19.5179 12.2402 20.8538L12.0051 21L11.761 20.8538Z"
                                                stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M15.7393 7.05301C16.8046 7.39331 17.5615 8.34971 17.6561 9.47499"
                                                stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>


                                </span>
                            </a>
                        </div>
                    </div>
                    <Loading v-if="$root.isEmptyObject(items)" />
                    <pagination class="mt-5" v-if="items.data != ''" :limit="2" :disabled="disabled" :data="items"
                        @pagination-change-page="changePage">
                    </pagination>
                    <div v-else-if="items.data == ''" class="alert alert-danger">
                        موردی یافت نشد
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
   import InstagramImpl from '@@/core/pages/Instagram/InstagramImpl'
   export default {
       extends: InstagramImpl
   }
</script>
<style scoped src="@@/core/pages/Instagram/InstagramStyle.css"></style>
