import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from "@@/core/mixins/mixins";
import FormModal from "@@/core/libraries/form/FormModal";
import {BTooltip, BTable, VBModal} from "bootstrap-vue";

export default {
  name: "Position",
  components: {
    FormModal,
    Loading,BTooltip, BTable
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/positions",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "label",
          label: "نام",
        },
        {
          key: "key",
          label: "کلید",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "advertisements",
          label: "بنرها",
        },
        {
          key: "edit",
          label: "ویرایش",
        },
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {},
  methods: {},
};
