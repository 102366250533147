<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link :disabled="hasPermission('write_size_chart') ? false : true" to="/admin/size-chart/create"
        class="btn btn-success">سایز چارت جدید</router-link>
      <router-link to="/admin/size-chart-type"
                   class="btn btn-secondary mr-2">انواع سایز چارت</router-link>
    </span>
    <b-tooltip v-if="!hasPermission('write_size_chart')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> سایز چارت ها</h5>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(chart)="data">
              <a @click="showSizeChart(data.item)" class="action-btns1 pt-1">
                <i class="fe fe-grid mx-2 "></i>
              </a>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate}}
            </template>
            <template v-slot:cell(edit)="data">

              <span :id="'edit-btn-' + data.item.id">
                <router-link :disabled="!hasPermission('modify_size_chart') ? true : false "
                  :to="{name:'size-chart' , params:{ id: data.item.id}}" class="action-btns1  pt-1 px-2">
                  <i class="feather feather-edit  text-primary"></i>
                </router-link>
              </span>
              <b-tooltip v-if="!hasPermission('modify_size_chart')" :target="'edit-btn-' + data.item.id"
                triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_size_chart') ? true : false " class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('delete_size_chart')" :target="'delete-btn-' + data.item.id"
                triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination v-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
            @pagination-change-page="changePage">
          </pagination>
          <div v-if="items.data == ''" class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
    <size-chart-modal :data="sizeChartData"></size-chart-modal>
    <!-- <sizeChartModal :data="sizeChartData" /> -->
  </main>
</template>
<script>
import SizeChartImpl from '@@/core/pages/sizeChart/SizeChart/SizeChartImpl'
export default {
  extends: SizeChartImpl
}
</script>
