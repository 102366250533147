import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import FormModal from "@@/core/libraries/form/FormModal";
import {BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput, VBToggle, VBModal} from "bootstrap-vue";

export default {
  name: "Provinces",
  components: {
    FormModal,
    Loading,
    BTooltip, BButton, BCard, BTable, BCollapse, BFormGroup, BFormInput
  },
  directives: {
    'b-toggle': VBToggle, 'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/provinces',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      filter: {
        name: '',
        status: ''
      },
      status_options: [{
        name: 'فعال',
        value: '1'
      },
        {
          name: 'غیرفعال',
          value: '0'
        },
        {
          name: 'همه',
          value: ''
        },
      ],
    }
  },
  mounted() {
    this.loadItems()
  },
  watch: {
    'filter.status': function (newVal) {
      if (typeof newVal === 'string' || typeof newVal === 'number') {
        this.filter.status = this.status_options.find(item => {
          return item.value == newVal
        })
      }
    }
  },
  methods: {
    async filterItem(page = 1) {
      this.searchParams = {
        search1: this.filter.name,
        searchBy1: 'name',
        search2: this.filter.status ? this.filter.status.value : '',
        searchBy2: 'status'
      }
    }
  }
}
