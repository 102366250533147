import {getStatusColor, getStatusLabel} from '@@/core/libraries/adminFunctions'
import dateFilterMixin from "@@/core/mixins/dateFilterMixin";

export default {
  name: "Chart2",
  mixins: [dateFilterMixin],
  props: {
    data: Object,
  },
  data() {
    return {
      chart: null,
      statuses: ['canceled', 'failed', 'delivered', 'reserved', 'in_progress', 'wait_for_payment', 'new'],
      labels: ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنجشنبه', 'جمعه'],
      /*
       canceled:Number,
       delivered:Number,
       in_progress:Number,
       new:Number,
       wait_for_payment:Number,
       */
      realData: this.data,
      type: 'week',
      loading: false
    }
  },
  mounted() {
    this.loadCharts()
  },
  methods: {
    getStatusLabel,
    getStatusColor,
    loadCharts() {
      if (this.chart) {
        this.chart.destroy()
      }
      let dataSets = Array(5);
      let statuses = {
        canceled: Array(7),
        delivered: Array(7),
        reserved: Array(7),
        in_progress: Array(7),
        new: Array(7),
        wait_for_payment: Array(7),
      };
      for (let [index, data] of Object.entries(this.realData)) {
        let _index = Number.parseInt(index) - 1;
        statuses.canceled[_index] = data.canceled
        statuses.delivered[_index] = data.delivered
        statuses.reserved[_index] = data.reserved
        statuses.in_progress[_index] = data.in_progress
        statuses.new[_index] = data.new
        statuses.wait_for_payment[_index] = data.wait_for_payment
      }

      ['canceled', 'delivered', 'in_progress', 'wait_for_payment', 'new'].forEach((status, index) => {
        let color = this.getStatusColor(status)
        dataSets[index] = ({
          label: getStatusLabel(status),
          data: statuses[status],
          backgroundColor: color,
          borderWidth: 2,
          hoverBackgroundColor: color,
          hoverBorderWidth: 0,
          borderColor: color,
          hoverBorderColor: color,
          borderDash: [5, 2],
        })
      })
      /* Chartjs (#sales-summary) */
      let myCanvas = document.getElementById("sales-summary");
      myCanvas.height = "300";
      this.chart = new Chart(myCanvas, {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: dataSets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          tooltips: {
            enabled: true,
          },
          scales: {
            yAxes: [{
              gridLines: {
                display: true,
                drawBorder: false,
                zeroLineColor: 'rgba(142, 156, 173,0.1)',
                color: "rgba(142, 156, 173,0.1)",
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                // suggestedMax: 100,
                // fontColor: "#8492a6",
                // userCallback: function (tick) {
                //   return tick.toString() + '%';
                // }
              },
            }],
            xAxes: [{
              barRadius: 0,
              ticks: {
                beginAtZero: true,
                fontColor: "#8492a6",
              },
              gridLines: {
                color: "rgba(142, 156, 173,0.1)",
                display: false
              },

            }]
          },
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      });

    },
    async loadType(type = null) {
      this.axiosCancelSource = this.$axios.CancelToken.source();
      type = type ? type : this.type;
      if (this.loading) {
        return
      }
      this.type = type;
      try {
        this.loading = true;
        const response = await this.$axios.get('admin/reports/chart2', {
          cancelToken: this.axiosCancelSource.token,
          params: {
            type,
            offset_year: this.moment().format('YYYY') - this.selectedYear,
            month: this.selectedMonth
          }
        });
        let data = response.data.data.data;
        this.realData = data;
        this.labels = this.getTypeLabels(type, Object.entries(data).length);
        this.loadCharts();
      } catch (e) {
        if (this.$axios.isCancel(e)) {
          return;
        }
        this.$root.notify(e)
      } finally {
        this.loading = false;
      }

    }
  },
  beforeDestroy() {
    this.axiosCancelSource.cancel()
  }
}
