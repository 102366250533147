<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link :disabled="hasPermission('write_specification') ? false : true" to="/admin/specifications/create"
                   class="btn btn-success">مشخصات جدید</router-link>
    </span>
    <b-tooltip v-if="!hasPermission('write_specification')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold"> مشخصات</h5>
        <div style="flex:1"></div>
        <b-button v-b-toggle.filter :variant="hasFilter() ? 'secondary' : 'primary'">
          <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group class="col-md-4">
                <b-form-input :disabled="disabled" placeholder="نام " v-model="filter.name">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4">
                <b-form-input :disabled="disabled" placeholder="گروه" v-model="filter.group">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-4">
                <multiselect placeholder="عمومی" v-model="filter.public" open-direction="bottom"
                             :options="status_options" label="name" track-by="value" :searchable="true"
                             :close-on-select="true"
                             :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-4">
                <multiselect placeholder="الزامی" v-model="filter.required" open-direction="bottom"
                             :options="status_options" label="name" track-by="value" :searchable="true"
                             :close-on-select="true"
                             :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-4">
                <multiselect placeholder="نمایش در فیلتر" v-model="filter.show_filter" open-direction="bottom"
                             :options="status_options" label="name" track-by="value" :searchable="true"
                             :close-on-select="true"
                             :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group class="col-md-4">
                <multiselect placeholder="وضعیت" v-model="filter.status" open-direction="bottom"
                             :options="status_options" label="name" track-by="value" :searchable="true"
                             :close-on-select="true"
                             :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button :disabled="disabled" type="submit" class="btn btn-primary px-5">جستجو</button>
              <button :disabled="disabled" @click="resetFilter" class="btn btn-warning px-5 mr-3">برداشتن فیلتر</button>
              <button :disabled="disabled" @click="$root.$emit('bv::toggle::collapse', 'filter')"
                      class="btn btn-secondary px-5 mr-3">بستن
              </button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <table :style="disabled ? 'opacity: 0.8' : ''"
               class="table table-responsive-sm table-bordered table-hover">
          <thead>
          <tr>
            <th scope="col"><i class="fas fa-arrows-alt-v"></i> شناسه</th>
            <th scope="col">نام</th>
            <th scope="col">لیبل</th>
            <th scope="col">وضعیت</th>
            <th scope="col">گروه</th>
            <th scope="col">عمومی</th>
            <th scope="col">الزامی</th>
            <th scope="col">نمایش در فیلتر</th>
            <th scope="col">تاریخ ثبت</th>
            <th scope="col">ویرایش</th>
            <th scope="col">حذف</th>
          </tr>
          </thead>
          <draggable v-model="items" tag="tbody" @change="sortSpecifications" v-bind="dragOptions">
            <tr class='flip-list' v-for="(item, index) in items" :key="item.id">
              <td scope="row">{{ item.id }}</td>
              <td scope="row">{{ item.name }}</td>
              <td scope="row">{{ item.label }}</td>
              <td scope="row">
                <span v-if="item.status == 1 " class="badge badge-success status">فعال</span>
                <span v-else-if="item.status == 0" class="badge badge-danger status">غیرفعال</span>
              </td>
              <td scope="row">{{ item.group }}</td>
              <td scope="row">
                <i v-if="item.public == 1 " class="fe fe-check text-success" style="font-size: 16px;"></i>
                <i v-else class="fe fe-x text-danger"></i>
              </td>
              <td scope="row">
                <i v-if="item.required == 1 " class="fe fe-check text-success" style="font-size: 16px;"></i>
                <i v-else class="fe fe-x text-danger"></i>
              </td>
              <td scope="row">
                <i v-if="item.show_filter == 1 " class="fe fe-check text-success" style="font-size: 16px;"></i>
                <i v-else class="fe fe-x text-danger"></i>
              </td>
              <td scope="row">
                {{ item.created_at | persianDate }}
              </td>
              <td scope="row">
              <span :id="'edit-btn-' + item.id">
                <router-link :disabled="!hasPermission('modify_specification') ? true : false "
                             :to="{name:'specification.edit' , params:{ id: item.id}}"
                             class="action-btns1  pt-1 px-2">
                  <i class="feather feather-edit  text-primary"></i>
                </router-link>
              </span>
                <b-tooltip v-if="!hasPermission('modify_specification')" :target="'edit-btn-' + item.id"
                           triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </td>
              <td scope="row">
              <span :id="'delete-btn-' + item.id">
                <a :disabled="!hasPermission('delete_specification') ? true : false " class="action-btns1  pt-1 px-2 "
                   @click="deleteItem(item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
                <b-tooltip v-if="!hasPermission('delete_specification')" :target="'delete-btn-' + item.id"
                           triggers="hover">
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </td>
            </tr>
          </draggable>
        </table>
        <Loading v-if="!Array.isArray(items) && $root.isEmptyObject(items)"/>
        <div v-else-if="items.length == 0" class="alert alert-danger">
          موردی یافت نشد
        </div>
      </div>
    </div>

  </main>
</template>
<script>
import SpecificationImpl from '@@/core/pages/specification/Specification/SpecificationImpl'

export default {
  extends: SpecificationImpl
}
</script>
