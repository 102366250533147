<template>
    <b-modal v-if="data != null" id="size-chart-modal" title="مشاهده سایز چارت" hide-footer size="lg"> 
        <table class="table card-table table-vcenter text-nowrap mb-0">
            <thead>
                <tr v-if="data[0]">
                    <th v-for="(cell , index) in data[0]" :key="index">
                        {{data[0][index]}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row , rowIndex) in data.slice(1)" :key="rowIndex" class="position-relative">
                    <th v-for="(cell , cellIndex) in row" :key="cellIndex" scope="row">
                        {{data[rowIndex + 1][cellIndex]}}
                    </th>
                </tr>
            </tbody>
        </table>
    </b-modal>
</template>
<script>
   import SizeChartModalImpl from '@@/core/components/size/SizeChartModal/SizeChartModalImpl'
   export default {
       extends: SizeChartModalImpl
   }
</script>