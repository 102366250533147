<template>
  <main class="mt-5">
    <span id="add-btn">
      <button :disabled="hasPermission('write_area') ? false : true" @click="createItem" v-b-modal.provinces
        class="btn btn-success">استان جدید</button>
    </span>
    <b-tooltip v-if="!hasPermission('write_area')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <FormModal :edit-model="edit" :mode="mode" @create="onCreate" @edit="onEdit" :url="url" :modal-name="$options.name"
      :create-rules="createRules" :edit-rules="editRules"></FormModal>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">استان ها</h5>
        <div style="flex:1"></div>
        <b-button v-b-toggle.filter :variant="hasFilter() ? 'secondary' : 'primary'">
          <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
        </b-button>
      </div>
      <b-collapse id="filter" class="mt-2 mx-5">
        <b-card>
          <form @submit.prevent="filterItem()">
            <div class="row mt-5">
              <b-form-group class="col-md-6">
                <b-form-input :disabled="disabled" placeholder="نام استان" v-model="filter.name">
                </b-form-input>
              </b-form-group>
              <b-form-group class="col-md-6">
                <multiselect placeholder="وضعیت" v-model="filter.status" open-direction="bottom"
                  :options="status_options" label="name" track-by="value" :searchable="true" :close-on-select="true"
                  :show-labels="false" :disabled="disabled">
                  <template slot="noOptions">
                    موردی یافت نشد
                  </template>
                  <template slot="noResult">
                    موردی یافت نشد
                  </template>
                </multiselect>
              </b-form-group>
            </div>
            <div class="text-center mt-3">
              <button :disabled="disabled" type="submit" class="btn btn-primary px-5">جستجو</button>
              <button :disabled="disabled" @click="resetFilter" class="btn btn-warning px-5 mr-3">برداشتن فیلتر</button>
              <button :disabled="disabled" @click="$root.$emit('bv::toggle::collapse', 'filter')"
                class="btn btn-secondary px-5 mr-3">بستن</button>
            </div>
          </form>
        </b-card>
      </b-collapse>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a :disabled="!hasPermission('modify_area') ? true : false " class="action-btns1  pt-1 px-2"
                  @click="editItem(data.index)">
                  <i class="feather feather-edit  text-primary"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('modify_area')" :target="'edit-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(status)="data">
              <span v-if="data.item.status == 1 " class="badge badge-success status">فعال</span>
              <span v-else-if="data.item.status == 0" class="badge badge-danger status">غیرفعال</span>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate}}
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_area') ? true : false " class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('delete_area')" :target="'delete-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
            @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProvincesImpl from '@@/core/pages/area/Provinces/ProvincesImpl'
export default {
  extends : ProvincesImpl
}
</script>