import {BFormGroup, BFormInput, BFormSelect, BFormSelectOption,
    BModal, BFormCheckbox, BForm, BTable, BFormFile} from "bootstrap-vue";
import configProvider from "@@/core/configProvider";

export default {
    props: {
        flash: Object,
        mode: {
            default: 'create',
            type: String
        },
        url: String,
    },
    configProvider,
    components: {
        BFormGroup, BFormInput, BFormSelect, BFormSelectOption,
        BModal, BFormCheckbox, BForm, BTable, BFormFile
    },
    data() {
        return {
            disabled: false,
            btnDisabled: false,
            discountTypes: [{
                value: 'flat',
                name: 'مبلغ'
            },
                {
                    value: 'percentage',
                    name: 'درصد'
                }
            ],
            fields: [{
                key: 'id',
                label: 'شناسه'
            },
                {
                    key: 'title',
                    label: 'عنوان'
                },
                {
                    key: 'pure_price',
                    label: 'قیمت خام'
                },
                {
                    key: 'discount_type',
                    label: 'نوع'
                },
                {
                    key: 'discount',
                    label: 'تخفیف'
                },
                {
                    key: 'quantity',
                    label: 'تعداد'
                },
                {
                    key: 'delete',
                    label: 'حذف'
                },
            ],
            products: [],
            files: [],
            imageBase64: '',
            productSelect: ''
        }
    },
    mounted() {
        this.loadProducts()
    },
    watch: {
        'flash.discount_type': function (newValue) {
            let products = this.products
            products.map(item => {
                item.pivot.discount_type = this.flash.discount_type.value
                item.pivot.discount = 0
                return item
            })
            return this.products = products
        },
        'flash.discount': function (newValue) {
            let products = this.products
            products.map(item => {
                item.pivot.discount = newValue
                return item
            })
            return this.products = products
        }
    },
    methods: {
        setDisabled(val) {
            this.disabled = val
        },
        loadProducts() {
            this.$axios.get("admin/products?all=true&search1=available&searchBy1=status")
                .then(response => {
                    let products = response.data.data.products
                    products.map(item => {
                        item.pivot = {
                            discount_type : '',
                            discount : 0,
                            salable_max : 0
                        }
                        return item
                    })
                    this.products = products
                })
        },
        setDiscount(item) {
            let product = item
            if (this.mode == 'create') {
                product.pivot = {
                    discount_type : '',
                    discount : 0,
                    salable_max : 0
                }
                product.pivot.discount_type == '' ? product.pivot.discount_type = this.flash.discount_type.value :
                    ''
                product.pivot.discount == 0 ? product.pivot.discount = this.flash.discount : ''
            } else {
                product.pivot.discount_type == '' ? product.pivot.discount_type = 'flat' : ''
                product.pivot.discount == 0 ? product.pivot.discount = 0 : ''
            }
            const findProduct = this.flash.products.find((item) => {
                return item.id == product.id;
            })
            if(findProduct) {
                this.$root.notify('این محصول قبلا انتخاب شده است.' , 'warning')
            } else {
                this.flash.products.push(product)
            }

            this.productSelect = ''
        },
        addImages(event) {
            const app = this
            var file = event.target.files[0];
            var reader = new FileReader();
            let image = ''
            reader.onloadend = function () {
                image = reader.result
                app.imageBase64 = image
            }
            reader.readAsDataURL(file);
        },
        deleteItem(index) {
            window.swal({
                title: "آیتم حذف شود؟",
                text: "این عملیات غیر قابل بازگشت خواهد بود",
                icon: "warning",

                showCancelButton: true,
                showConfirmButton: true,
                dangerMode: true,
                buttons: ["خیر", "بله حذف کن"]
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.$root.$delete(this.flash.products, index)
                    }
                })
                .catch(() => {

                })
        },
        async submit(e) {
            e.preventDefault()
            this.disabled = true

            try {
                let formData = new FormData(e.target);
                formData.append('discount_type', this.flash.discount_type ? this.flash.discount_type.value :
                    '');
                formData.append('start_date', this.flash.start_date);
                formData.append('end_date', this.flash.end_date);
                // formData.append('image',  this.imageBase64);
                formData.append('_method', this.mode == 'edit' ? 'put' : 'post');
                const response = await this.$axios.post(this.url, formData);
                this.$root.notify(response, 'success');
                this.$router.push('/admin/flash');
            } catch (error) {
                this.$root.notify(error)
            }
            this.disabled = false
        },
    }
}
