<template>
  <main class="mt-5">
    <span id="add-btn">
      <router-link to="/admin/newsletters/create" 
        :disabled="hasPermission('write_newsletters') ? false : true"
        class="btn btn-success"
      >
        خبرنامه جدید
      </router-link>
    </span>
    <b-tooltip
      v-if="!hasPermission('write_newsletters')"
      target="add-btn"
      triggers="hover"
    >
      شما به این بخش دسترسی ندارید
    </b-tooltip>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">خبرنامه</h5>
        <div style="flex:1"></div>
      </div>

      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(send_at)="data">
              {{ data.item.send_at | persianDate }}
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status == 'success'"
                class="badge badge-success status"
                >ارسال شده</span
              >
              <span
                v-else-if="data.item.status == 'failed'"
                class="badge badge-danger status"
                >ارسال نشده </span
              >
            </template>
            <template v-slot:cell(show)="data">
              <router-link
                :to="{ name: 'newsletters.show', params: { id: data.item.id } }"
                class="action-btns1  pt-1 px-2"
              >
                <i class="fe fe-eye  text-info"></i>
              </router-link>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_newsletters') ? true : false " class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('delete_newsletters')" :target="'delete-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination
            v-else-if="items.data != ''"
            :disabled="disabled"
            :limit="2"
            :data="items"
            @pagination-change-page="changePage"
          >
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import NewslettersImpl from '@@/core/pages/newsletters/Newsletters/NewslettersImpl'
export default {
  extends: NewslettersImpl
}
</script>
