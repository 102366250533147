import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import {BTooltip, BTable} from "bootstrap-vue";

export default {
  name: "Pages",
  components: {
    Loading,
    BTooltip, BTable
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/pages',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'title',
          label: 'عنوان'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
    }
  },
  mounted() {
    this.loadItems()
  }
}
