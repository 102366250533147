import Loading from '@@/core/components/shared/Loading/Loading'
import PageMaker from '@@/core/components/page/PageMaker/PageMaker'
import {BButton, BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  name: "PageCreateEdit",
  components: {Loading,
    BButton, BFormGroup, BFormInput,
    PageMaker},
  data() {
    return {
      mode: this.$route.params.id ? 'edit' : 'create',
      modelId: this.$route.params.id,
      model: {
        text: '',
        title: '',
        slug: ''
      },
      loading: false,
      disabled: false,
      url: `admin/pages` + (this.$route.params.id ? `/${this.$route.params.id}` : '')
    }
  },
  created() {
    if (this.mode === 'edit') {
      this.loadItem()
    }
  },
  methods: {
    async loadItem() {
      try {
        this.loading = true;
        const response = await this.$axios.get(this.url);
        this.model = response.data.data.page;
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async submit(e) {
      if (!this.model.title) {
        return this.$root.notify('فیلد عنوان الزامی است', 'warning')
      }
      try {
        const formData = new FormData(e.target);
        formData.set('text', this.$refs.pageMaker.readData())
        formData.set('_method', this.mode == 'create' ? 'POST' : 'PUT')
        this.disabled = true;
        const response = await this.$axios.post(this.url, formData);
        this.$root.notify(response.data.message, 'success');
        this.$router.push('/admin/pages');
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    }
  }
}
