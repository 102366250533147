<template>
    <main class="mt-5">
        <span id="add-btn">
            <button :disabled="hasPermission('write_role') ? false : true" @click="newRole" v-b-modal.role-modal
                class="btn btn-success">نقش جدید</button>
        </span>
        <b-tooltip v-if="!hasPermission('write_role')" target="add-btn" triggers="hover">
            شما به این بخش دسترسی ندارید
        </b-tooltip>
        <div class="card mt-3">
            <div class="card-header  border-0">
                <h5 class="card-title font-bold"> نقش ها</h5>
            </div>
            <div class="card-body">
                <div>
                    <b-table responsive hover bordered :items="items.data" :fields="fields">
                        <template v-slot:cell(created_at)="data">
                            {{ data.item.created_at | persianDate}}
                        </template>
                        <template v-slot:cell(edit)="data">
                            <span :id="'edit-btn-' + data.item.id">
                                <a @click="editItem(data.item.id,data.index)"
                                    :disabled="disabled || !hasPermission('modify_role') ? true : false "
                                    class="action-btns1  pt-1 px-2">
                                    <i class="feather feather-edit  text-primary"></i>
                                </a>
                            </span>
                            <b-tooltip v-if="!hasPermission('modify_role')" :target="'edit-btn-' + data.item.id"
                                triggers="hover">
                                شما به این بخش دسترسی ندارید
                            </b-tooltip>
                        </template>
                        <template v-slot:cell(delete)="data">
                            <span :id="'delete-btn-' + data.item.id">
                                <a :disabled="!hasPermission('delete_role') ? true : false "
                                    class="action-btns1  pt-1 px-2 " @click="deleteItem(data.item.id)">
                                    <i class="feather feather-trash-2 text-danger"></i>
                                </a>
                            </span>
                            <b-tooltip v-if="!hasPermission('delete_role')" :target="'delete-btn-' + data.item.id"
                                triggers="hover">
                                شما به این بخش دسترسی ندارید
                            </b-tooltip>
                        </template>
                    </b-table>
                    <Loading v-if="$root.isEmptyObject(items)" />
                    <div v-if="items.data == ''" class="alert alert-danger">
                        موردی یافت نشد
                    </div>
                </div>
            </div>
        </div>
        <RoleModal :mode="mode" :role="role" :url="submit_url" />
    </main>
</template>
<script>
import RolesImpl from '@@/core/pages/Roles/RolesImpl'
export default {
    extends: RolesImpl
}
</script>