<template>
  <main class="mt-5">
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">
          ثبت خبرنامه
        </h5>
        <div style="flex:1"></div>
        <Back />
      </div>
      <div class="card-body">
        <form @submit.prevent="send">
          <div class="row">
            <b-form-group class="col-md-6">
              <label for="title"> عنوان <i class="text-danger">*</i></label>
              <b-form-input v-model="newsletters.title"></b-form-input>
            </b-form-group>
            <b-form-group class="col-md-6">
              <label for=""> تاریخ ارسال </label>
              <date-picker
                type="datetime"
                v-model="newsletters.send_at"
                format="YYYY-MM-DD HH:mm:ss"
                display-format="jYYYY-jMM-jDD HH:mm:ss"
              />
            </b-form-group>
          </div>

          <b-form-group>
            <label for="title"> متن <i class="text-danger">*</i></label>
            <ckeditor
              id="newsletters-body"
              v-model="newsletters.body"
            ></ckeditor>
          </b-form-group>
          <div class="text-center mt-3">
            <button type="submit" class="btn btn-success" :disabled="disabled">
              ثبت
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import NewslettersCreateImpl from '@@/core/pages/newsletters/NewslettersCreate/NewslettersCreateImpl'
export default {
  extends: NewslettersCreateImpl
}
</script>
