import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import FormModal from "@@/core/libraries/form/FormModal";
import {BTooltip, BTable, VBModal} from "bootstrap-vue";

export default {
  name: "Unit",
  components: {
    FormModal,
    Loading,
      BTooltip, BTable
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/units',
      fields: [{
        key: 'id',
        label: 'شناسه'
      },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'status',
          label: 'وضعیت'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ]
    }
  },
  mounted() {
    this.loadItems()
  }
}
