<template>
  <div class="mt-4">
    <form v-if="!loading" @submit.prevent="submit">
      <div class="row">
        <div class="col-6">
          <b-form-group label="عنوان">
            <b-form-input type="text" name="title" v-model="model.title"/>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="اسلاگ (اختیاری)">
            <b-form-input type="text" name="slug" v-model="model.slug"/>
          </b-form-group>
        </div>
      </div>

      <PageMaker ref="pageMaker" v-model="model.text" />
      <b-button :disabled="disabled" variant="primary" type="submit">ثبت</b-button>
    </form>
    <Loading v-else />
  </div>
</template>

<script>
import PageCreateEditImpl from '@@/core/pages/page/PageCreateEdit/PageCreateEditImpl'
export default {
  extends: PageCreateEditImpl
}
</script>

<style scoped>

</style>
