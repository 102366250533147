<template>
    <main class="mt-5 in-product-set">
        <div class="card mt-3">
            <div class="card-header  border-0">
                <h5 class="card-title font-bold">{{ $route.params.id ? 'ویرایش ست' : 'ست جدید' }}</h5>
                <div style="flex:1"></div>
                <Back />
            </div>
            <div class="card-body">
                <SetForm ref="setForm" :mode="$route.params.id ? 'edit' : 'create'" :url="url" v-model="set"
                    :products="products" />
            </div>
        </div>
    </main>
</template>
<script>
import SetCreateEditImpl from "@@/core/pages/sets/SetCreateEdit/SetCreateEditImpl"
export default {
    extends: SetCreateEditImpl
}
</script>
<style scoped src="@@/core/pages/sets/SetCreateEdit/SetCreateEditStyle.css"></style>