import ImageHolder from '@@/core/components/shared/ImageHolder/ImageHolder'
import {BModal, BFormGroup, BFormCheckbox, BFormFile, BFormInput} from "bootstrap-vue";

export default {
  components: {
    ImageHolder,BModal, BFormGroup, BFormCheckbox, BFormFile, BFormInput
   },
  name: "AdvertiseModal",
  props: {
    linkables: Array,
    positions: Array,
    mode: {
      default: "create",
      type: String,
    },
    value: Object,
    labelMultiselect: String,
    positionId: null
  },
  data() {
    return {
      disabled: false,
      url: "",
      models: [],
      modelLable: this.labelMultiselect,
      linkUrl: false,
      advertise: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.advertise = newVal;
    },
    advertise(newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.disabled = true;
      try {
        this.url =
          this.mode == "edit"
            ? `admin/advertise/${this.advertise.id}`
            : "admin/advertise";
        let formData = new FormData(this.$refs.form);
        this.mode == "edit" ? formData.append("_method", "PUT") : "";
        formData.append(
          "position_id",
          this.advertise.position_id && this.positionId == null ? this.advertise.position_id : this.positionId
        );
        formData.append(
          "start",
          this.advertise.start
            ? window.moment(this.advertise.start,'YYYY.MM.DD').unix()
            : ''
        );
        formData.append(
          "end",
          this.advertise.end
            ? window.moment(this.advertise.end,'YYYY.MM.DD').unix()
            : ''
        );
        formData.append('new_tab' , this.advertise.new_tab == 1 ? 1 : 0)
        const response = await this.$axios.post(this.url, formData);

        this.$root.notify(response.data.message, "success");
        this.$emit("set-advertise", response.data.data.advertise);
        this.$root.$emit("bv::hide::modal", "advertise-modal");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
    loadModels(item) {
      this.advertise.linkable_id = null;
      if (item) {
        this.models = item.models;
        if (this.models != null && this.models != "" && this.models[0].title) {
          this.modelLable = "title";
          this.linkUrl = false;
        } else if (
          this.models != null &&
          this.models != "" &&
          this.models[0].name
        ) {
          this.modelLable = "name";
          this.linkUrl = false;
        } else if (this.models != null && this.models == "link_url") {
          this.linkUrl = true;
        }
        if (this.models == null) {
          this.linkUrl = false;
        }
      }
    },
  },
}
