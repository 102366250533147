import {BModal} from "bootstrap-vue";

export default {
  components: {
    BModal
  },
  name: 'SizeChartTypeShowModal',
  props: {
    sizeChartType: Object
  }
}
