<template>
  <b-modal ok-only ok-title="بستن" ok-variant="warning" id="attributes-info" title="ویژگی ها">
    <template v-if="attributesInfo && !(Array.isArray(attributesInfo) && attributesInfo.length === 0)">
      <div v-for="[key, value] of Object.entries(attributesInfo)" class="mt-3 mb-5">
        <h5 class="font-weight-bold">{{ key }}</h5>
        <hr class="mt-1" />
        <ul>
          <li v-for="[_key, _value] of Object.entries(value)">
            <span>{{ _key }}: </span><span>{{ _value }}</span>
          </li>
        </ul>
      </div>
    </template>
    <template v-else>ویژگی وجود ندارد</template>
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue'

export default {
  name: "AttributesInfoModal",
  components: {BModal},
  props: {
    attributesInfo: Object
  }
}
</script>

<style scoped>

</style>
