import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from "@@/core/mixins/mixins";
import {BTooltip, BTable} from "bootstrap-vue";

export default {
  name: "Newsletters",
  components: {
    Loading, BTooltip, BTable
  },
  mixins: [mixins],
  data() {
    return {
      url: "admin/newsletters",
      fields: [
        {
          key: "id",
          label: "شناسه",
        },
        {
          key: "title",
          label: "عنوان",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "send_at",
          label: "تاریخ ارسال",
        },
        {
          key: "created_at",
          label: "تاریخ ثبت",
        },
        {
          key: "show",
          label: "مشاهده",
        },
        {
            key: 'delete',
            label: 'حذف'
          },
      ],
    };
  },
  mounted() {
    this.loadItems();
  },
  watch: {},
  methods: {},
};
