import Loading from '@@/core/components/shared/Loading/Loading'
import SizeChartTypeModal from "@@/core/components/size/SizeChartTypeModal/SizeChartTypeModal";
import Back from '@@/core/components/shared/Back/Back'
import {BTooltip, BTable} from "bootstrap-vue";

import mixins from '@@/core/mixins/mixins'

export default {
  name: "SizeChartType",
  components: {
    Loading,
    SizeChartTypeModal,
    Back,
    BTooltip, BTable
  },
  mixins: [mixins],
  data() {
    return {
      hasPagination: false,
      items: null,
      url: 'admin/size_chart_types',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'name',
          label: 'عنوان'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'show',
          label: 'مشاهده'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ]
    }
  },
  mounted() {
    this.loadItems()
  }
}
