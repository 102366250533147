import Back from '@@/core/components/shared/Back/Back'
import specificationForm from '@@/core/components/specification/SpecificationForm/SpecificationForm'

export default {
  components: {
    Back,
    specificationForm
  },
  data() {
    return {
      title: '',
      url: '',
      axiosCancelSource: null,
      specification: {
        name: '',
        label: '',
        type: null,
        group: '',
        status: 1,
        show_filter: 0,
        public: 0,
        required: 0,
        values: [{}]
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.url = 'admin/specifications/' + this.$route.params.id
      this.getSpecification()
    } else {
      this.$refs.specificationForm.setDisabled(false)
      this.url = 'admin/specifications'
    }
  },
  methods: {
    async getSpecification() {
      try {
        this.axiosCancelSource = this.$axios.CancelToken.source();
        const response = await this.$axios.get(this.url, {
          cancelToken: this.axiosCancelSource.token
        })
        this.specification = response.data.data.specification
        this.specification.type == 'select' ? this.specification.type = {
            value: 'select',
            name: 'تک مقدار'
          } : this.specification.type == 'multi_select' ? this.specification.type = {
            value: 'multi_select',
            name: 'چند مقدار'
          } : this.specification.type = {value:'text', name:'متنی'}

          this.specification.values == '' ? this.specification.values = [{}] : ''
          this.$refs.specificationForm.setDisabled(false)
      } catch (error) {
        if (this.$axios.isCancel(error)) {
          return;
        }
        this.$root.notify(error)
      }
    }
  }
}
