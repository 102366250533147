<template>
  <main class="mt-5">
    <span id="add-btn">
      <button :disabled="hasPermission('write_customer') ? false : true" @click="createItem"
        class="btn btn-success">نقش جدید</button>
    </span>
    <b-tooltip v-if="!hasPermission('write_customer')" target="add-btn" triggers="hover">
      شما به این بخش دسترسی ندارید
    </b-tooltip>

    <FormModal :edit-model="edit" :mode="mode" @create="onCreate" @edit="onEdit" :url="url" :modal-name="$options.name"
      :create-rules="createRules" :edit-rules="editRules"></FormModal>
    <div class="card mt-3">
      <div class="card-header  border-0">
        <h5 class="card-title font-bold">نقش ها</h5>
        <div style="flex:1"></div>
      </div>
      <div class="card-body">
        <div>
          <b-table responsive hover bordered :items="items.data" :fields="fields">
            <template v-slot:cell(code)="data">
              <span class="px-2 py-1 badge  color-code"
                :style="{backgroundColor: data.item.code , color: $root.dynamicColor(data.item.code) }">{{ data.item.code }}</span>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
            </template>
            <template v-slot:cell(edit)="data">
              <span :id="'edit-btn-' + data.item.id">
                <a :disabled="!hasPermission('modify_customer') ? true : false " class="action-btns1  pt-1 px-2"
                  @click="editItem(data.index)">
                  <i class="feather feather-edit  text-primary"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('modify_customer')" :target="'edit-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
            <template v-slot:cell(delete)="data">
              <span :id="'delete-btn-' + data.item.id">
                <a :disabled="!hasPermission('delete_customer') ? true : false " class="action-btns1  pt-1 px-2 "
                  @click="deleteItem(data.item.id)">
                  <i class="feather feather-trash-2 text-danger"></i>
                </a>
              </span>
              <b-tooltip v-if="!hasPermission('delete_customer')" :target="'delete-btn-' + data.item.id" triggers="hover">
                شما به این بخش دسترسی ندارید
              </b-tooltip>
            </template>
          </b-table>
          <Loading v-if="$root.isEmptyObject(items)" />
          <pagination v-else-if="items.data != ''" :disabled="disabled" :limit="2" :data="items"
            @pagination-change-page="changePage">
          </pagination>
          <div v-else class="alert alert-danger">
            موردی یافت نشد
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import FormModal from "@@/core/libraries/form/FormModal";
import Loading from "@@/core/components/shared/Loading/Loading";
import {BButton, BCard, BCollapse, BFormGroup, BFormInput, BTable, BTooltip, VBToggle} from "bootstrap-vue";
import mixins from "@@/core/mixins/mixins";
export default {
  name: 'CustomerRole',
  components: {
    FormModal,
    Loading,
    BTooltip, BButton, BCollapse, BCard, BFormGroup, BFormInput, BTable
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/customer_roles',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'see_expired',
          label: 'دیدن محصولات زمانبندی شده'
        },
        {
          key: 'created_at',
          label: 'تاریخ ثبت'
        },
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {}
}
</script>
