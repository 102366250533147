import Loading from '@@/core/components/shared/Loading/Loading'
import mixins from '@@/core/mixins/mixins'
import FormModal from "@@/core/libraries/form/FormModal";
import {BTooltip, VBModal} from "bootstrap-vue";
import draggable from "vuedraggable";

export default {
  name: 'faqs',
  components: {
    Loading,
    FormModal,
    BTooltip,
    draggable
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      url: 'admin/f_a_qs',
      group: '',
      faqs: null,
      mode: 'create',
      disabled: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: this.disabled,
        ghostClass: "ghost"
      }
    }
  },
  created() {
    this.loadfaq()
  },
  methods: {
    async loadfaq() {
      const response = await this.$axios.get(this.url)
      if (response.data.data.store_rules) {
        this.createRules = response.data.data.store_rules;
      }
      if (response.data.data.update_rules) {
        this.editRules = response.data.data.update_rules;
      }
      this.faqs = response.data.data.fAQs
    },
    deleteItem(id) {
      window.swal({
        title: "آیتم حذف شود؟",
        text: "این عملیات غیر قابل بازگشت خواهد بود",
        icon: "warning",

        showCancelButton: true,
        showConfirmButton: true,
        dangerMode: true,
        buttons: ["خیر", "بله حذف کن"]
      })
          .then((willDelete) => {
            if (willDelete) {
              this.$axios.delete(`admin/f_a_qs/${id}`)
                  .then((response) => {
                    this.faqs = this.faqs.filter(item => item.id != id)
                    window.swal({
                      icon: "success",
                      showConfirmButton: true,
                      text: response.data.message,
                      button: "باشه",
                    })
                  })
                  .catch(error => {
                    window.swal({
                      title: 'خطا',
                      showConfirmButton: true,
                      text: error.response.data.message,
                      button: "باشه",
                    })
                  })
            }
          })
          .catch(() => {

          })
    },
    editItem(index) {
      if (!(this.edit && this.edit._index === index) || this.mode !== 'edit') {
        let item;
        item = window.clone(this.faqs[index])
        item._index = index
        this.edit = item
        this.mode = 'edit'
      }

      this.$root.$emit('bv::show::modal', 'modal-' + this.$options.name)
    },
    onCreate(data) {
      let objectKey = Object.keys(data.data)[0]
      this.faqs.unshift(data.data[objectKey])
    },
    onEdit(data, index) {
      let objectKey = Object.keys(data.data)[0]
      let model = data.data[objectKey];
      this.$root.$set(this.faqs, index, model);
    },
    async sortfaq() {
      let orders = [];

      this.faqs.forEach((item) => {
        orders.push(item.id)
      });
      try {
        this.disabled = true;
        const response = await this.$axios.post('admin/f_a_qs/sort', {
          ids: orders,
        })
        this.$root.notify(response.data.message, 'success')
        // window.swal({
        //     title: response.data.message,
        // });
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    }
  }
}
