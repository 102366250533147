import Back from '@@/core/components/shared/Back/Back'
import SizeForm from '@@/core/components/size/SizeChartForm/SizeChartForm'
import {BModal, BFormGroup, BFormInput} from "bootstrap-vue";

export default {
  name: 'SizeChartTypeModal',
  components: {
    Back,
    SizeForm,
    BModal, BFormGroup, BFormInput
  },
  props: {
    item: Object,
    mode: String
  },
  watch: {
    mode: {
      immediate: true,
      handler: function (newVal) {
        if (this.mode === 'create') {
          this.values = ['']
          this.name = ''
        }
      },
    },
    item: {
      immediate: true,
      handler: function (newVal) {
        if (!newVal) return;
        this.name = this.item.name
        this.values = this.item.values.map(val => val.name);
      },
    }
  },
  data() {
    return {
      name: '',
      url: 'admin/size_chart_types',
      disabled: false,
      values: ['']
    }
  },
  mounted() {

  },
  methods: {
    addValue(index) {
      this.values.splice(index + 1, 0, '');
    },
    async submit() {
      this.disabled = true
      try {
        const response = await this.$axios.post(this.url + (this.mode == 'edit' ? '/' + this.item.id : ''), {
          values: this.values,
          name: this.name,
          _method: this.mode == 'edit' ? 'put' : 'post'
        });
        this.$root.notify(response, 'success')
        this.$root.$emit('bv::hide::modal', 'modal-SizeChartType');
        this.$emit(this.mode, response.data, this.item._index)
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
  }
}
